<template src="@/../../../templates/brand/components/templates/shop/Cart.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapActions, mapState, mapGetters } from 'vuex'
import accounting from 'accounting'
import countDown from '@/components/Countdown.vue'

// logic
export default {
  name: 'Cart',
  components: { countDown },
  async created () {
    this.cart = {}
    // this.cartProducts = []
    if (!this.locations || !this.locations.length) {
      this.getLocations().then(() => {
        _.each(l => {
          this.expanded[l._id] = false
        }, this.locations)
      })
    }

    if (!this.collections.length) await this.getCollections()
    if (!this.programs.length) await this.getPrograms()
    this.setContext()

  },
  data () {
    return {
      loaded: false,
      cart: {},
      cartPath: '',
      expanded: {},
      isCollection: false,
      orphanProducts: []
      // collection: {url: ''}
    }
  },
  watch: {
    $route: function () {
      this.loaded = false
      this.setContext()
    }
  },
  computed: {
    ...mapState({
      collectionProducts: ({ Product }) => Product.listFamilies, // products,
      collections: ({ Collection }) => _.sortBy(item => item.order, Collection.collections),
      programs: ({ Collection }) => Collection.programs,
      cartProds: ({ Cart }) => Cart.cart,
      customorderProds: ({ Product }) => Product.customorderProducts,
      locations: ({ Location }) => Location.locations,
      user: ({ App }) => App.user
    }),
    ...mapGetters({
      appconfig: 'const'
    }),
    cartTitle () {
      if (_.isEmpty(this.cart) || !this.collection || !this.collection.url)
        return ''
      return this.collection.content[this.$i18n.locale].name
    },
    collection () {
      if (this.isCollection && (!this.collections || !this.collections.length))
        return {}
      if (this.cartPath === 'cart')
        return this.collections.find(c => c.settings.cart === 'default')
      if (this.cartPath === 'customorders')
        return this.collection.find(c => c.allowCustom)
      let _ent = this.isCollection ? this.collections : this.programs
      return _ent.find(c => c.settings.cart === this.cartPath)
    },
    subtotal () {
      if (!this.cart || !this.cart.details) return 0
      try {
        if (
          !this.collection.settings ||
          !this.collection.settings.multilocation
        ) {
          return _.reduce(
            (sum, i) => {
              let _adj = this.adjustedPrice(i)
              sum += _adj.subtotal
              if (this.collection.allowCustom)
                sum += this.adjustedCustomPrice(i).subtotal
              return sum
            },
            0,
            this.cartProducts
          )
        } else {
          return _.reduce(
            (sum, i) => {
              _.each(p => {
                let _adj = this.adjustedPrice(p)
                sum += _adj.subtotal
                if (this.collection.allowCustom)
                  sum += this.adjustedCustomPrice(p).subtotal
              }, this.cartProducts[i])
              return sum
            },
            0,
            _.keys(this.cartProducts)
          )
        }
      } catch (e) {
        return 0
      }
    },
    cartIsEmpty () {
      return !_.keys(this.cart.items).length
    },
    canCheckout () {
      if (_.isEmpty(this.cart.items)) return false
      if (!this.collection.settings.checkout) return false
      const _minQty = this.collection.settings.minQty || 1
      if (this.collection.settings.collectionMinQty) {
        if (this.collection.settings.excludeProductMin) {
          const _excludes = this.collectionProducts
            .filter(p => p.minQty)
            .map(p => p.SKU)
          const _exclude = _.sum(
            _.map(
              k => (~_excludes.indexOf(k) ? this.cart.items[k] : 0),
              _.keys(this.cart.items)
            )
          )
          const _include = _.sum(
            _.map(
              k => (!~_excludes.indexOf(k) ? this.cart.items[k] : 0),
              _.keys(this.cart.items)
            )
          )
          return (
            (_include && _include >= this.collection.settings.minQty) ||
            _exclude
          )
        } else {
          return (
            _.sum(_.values(this.cart.items)) >= this.collection.settings.minQty
          )
        }
      } else {
        return _.sum(_.values(this.cart.items)) >= _minQty
      }
    },
    cartProducts () {
      if (!this.collection || !this.cartProds || !this.cart || !this.cart.items)
        return []
      const _prods = _.isArray(this.cartProds)
        ? this.cartProds
        : _.values(this.cartProds)
      if (
        !this.collection.settings ||
        !this.collection.settings.multilocation
      ) {
        return _.map(i => {
          //   console.info(' ::::: loop', i.SKU)
          let _p = _.assign({}, i)
          _p.qty = this.cart.items[i.SKU]
          _p.familyQty = this.familyQty(i)
          return _p
        }, _.sortBy(['SKU'], _prods))
      } else {
        return _.reduce(
          (acc, i) => {
            _.each(l => {
              let _prod = _prods.find(p => p.SKU === i)
              if (!acc[l]) {
                acc[l] = [
                  _.assign(_prod, { qty: this.cart.details[i].locations[l] })
                ]
              } else {
                acc[l].push(
                  _.assign(_prod, { qty: this.cart.details[i].locations[l] })
                )
              }
            }, _.keys(this.cart.details[i].locations))
            return acc
          },
          {},
          _.keys(this.cart.items).sort()
        )
      }
    },
    cartLocations () {
      try {
        return _.reduce(
          (acc, i) => {
            _.each(l => {
              if (!acc[l]) {
                acc[l] = {}
                acc[l].name = this.locations.find(loc => loc._id === l).content[
                  this.$i18n.locale
                ].name
                acc[l].items = 0
                acc[l].total = 0
              }
              acc[l].items += this.cart.details[i].locations[l]
              acc[l].total +=
                _.find(
                  p => p.SKU === i,
                  _.isArray(this.cartProds)
                    ? this.cartProds
                    : _.values(this.cartProds)
                ).price * this.cart.details[i].locations[l]
            }, _.keys(this.cart.details[i].locations))
            return acc
          },
          {},
          _.keys(this.cart.items)
        )
      } catch (e) {
        return []
      }
    },
    products () {
      // return this.cartPath === 'cart'
        return _.isArray(this.cartProds)
          ? this.cartProds
          : _.values(this.cartProds)
        // : this.customorderProds
    },
    inventoryWarning () {
      const _prods = _.isArray(this.cartProds)
        ? this.cartProds
        : _.values(this.cartProds)
      return this.cartPath === 'cart' && _prods.some(p => p.qty > p.inventory)
        ? // ? 'One or more items in your cart have insufficient inventory to complete your order. Please update your quantities so they don\'t exceed available supply.'
          this.$t(`client.checkout.inventoryWarningCart`)
        : ''
    },
    editable () {
      if (!this.isCollection) return new Date() < new Date(this.collection.ends)
      return true
    }
  },
  methods: {
    ...mapActions([
      'getCollections',
      'getCollectionProducts',
      'getPrograms',
      'getProgramProducts',
      'getLocations',
      'getCartProducts',
      'getCustomorderProducts',
      'checkUser',
      'profileUpdate',
      'clearCartMemory'
    ]),
    getUrl (item) {
      if (!this.cart || !this.cart.details || !this.cart.details[item.SKU]) return ''
        let _url = `/${this.isCollection ? 'shop' : 'programs'}/${this.cart.details[item.SKU].url}/product/${item.familyKey}`
        _url += item.variationField.key && item.relatedBy !== 'variation'
          ? `/${item.variationField.key}`
          : ''
        return _url
    },
    setCart () {
      this.cart =
        _.get(this.$route.query.cartid, this.user.profile) ||
        _.get(this.$route.query.cartid, this.user.profile.shoppingcarts)
    },
    expand (id) {
      const _exp = _.assign({}, this.expanded)
      _exp[id] = !_exp[id]
      this.expanded = _exp
    },
    familyQty (item) {
      return _.sum(
        _.map(i =>
            item.familyKey === i.familyKey &&
            item.variationKey === i.variationKey
              ? this.cart.items[i.SKU]
              : 0,
          this.products
        )
      )
    },
    adjustedPrice (item) {
      const _tiers = _.sortBy(['volume'], item.adjustments)
      if (!item.adjustments.length) {
        return {
          val: item.price,
          tier: -1,
          subtotal: item.qty * item.price
        }
      }
      if (this.familyQty(item) < _tiers[0].volume) {
        return {
          val: item.price,
          tier: -1,
          subtotal: item.qty * item.price
        }
      }
      if (this.familyQty(item) >= _tiers[_tiers.length - 1].volume) {
        return {
          val: _tiers[_tiers.length - 1].value,
          tier: _tiers[_tiers.length - 1].volume,
          subtotal: item.qty * _tiers[_tiers.length - 1].value
        }
      }
      let _next = _.findIndex(i => i.volume >= this.familyQty(item))(_tiers)
      _next > 0 ? (_next -= 1) : _next
      return {
        val: _tiers[_next].value,
        tier: _tiers[_next].volume,
        subtotal: item.qty * _tiers[_next].value
      }
    },
    adjustedCustomPrice (item) {
      if (!item || !this.cart.details[item.SKU]) return {val: 0, unitVal: 0, subtotal: 0}
      const _tiers = _.sortBy(['volume'], item.custom.adjustments)
      let _logos = _.keys(this.cart.details[item.SKU].customizations)
      let _sorted = _.sortBy(['volume'], item.custom.adjustments)
      if (!item.custom.adjustments.length) {
        return {
          val: item.custom.price,
          unitVal: (_logos.length ? _logos.length - 1 : 0) * item.custom.price,
          // tier: -1,
          locations: _logos,
          subtotal:
            (_logos.length ? _logos.length - 1 : 0) *
            item.qty *
            item.custom.price
        }
      }
      if (this.familyQty(item) < _tiers[0].volume) {
        return {
          val: item.custom.price,
          unitVal: (_logos.length ? _logos.length - 1 : 0) * item.custom.price,
          // tier: -1,
          locations: _logos,
          subtotal:
            (_logos.length ? _logos.length - 1 : 0) *
            item.qty *
            item.custom.price
        }
      }
      if (this.familyQty(item) >= _tiers[_tiers.length - 1].volume) {
        return {
          val: _tiers[_tiers.length - 1].value,
          unitVal:
            (_logos.length ? _logos.length - 1 : 0) *
            _tiers[_tiers.length - 1].value,
          // tier: _tiers[_tiers.length-1].volume,
          locations: _logos,
          subtotal:
            (_logos.length ? _logos.length - 1 : 0) *
            item.qty *
            _tiers[_tiers.length - 1].value
        }
      }
      let _logotier = _.findIndex(i => i.volume > this.familyQty(item), _sorted)
      _logotier > 0 ? (_logotier -= 1) : _logotier
      return {
        val: _tiers[_logotier].value,
        unitVal:
          (_logos.length ? _logos.length - 1 : 0) * _tiers[_logotier].value,
        locations: _logos,
        subtotal:
          (_logos.length ? _logos.length - 1 : 0) *
          item.qty *
          item.custom.adjustments[_logotier].value
      }
    },
    updateItem (item, loc) {
      const _profile = _.assign({}, this.user.profile)
      const _val = !this.collection.settings.multilocation
        ? parseInt(this.$el.querySelector('input[data-sku=' + item.SKU + ']').value, 10)
        : parseInt(this.$el.querySelector(`input[data-sku=${item.SKU}][data-loc=${loc}]`).value, 10)
      if (_val < item.minQty) {
        this.$vex.dialog.alert({
          message: `The minimum quantity for this item is ${item.minQty}`
        })
        !this.collection.settings.multilocation
          ? this.$el.querySelector('input[data-sku=' + item.SKU + ']')
            .value = this.cart.items[item.SKU]
          : this.$el.querySelector(`input[data-sku=${item.SKU}][data-loc=${loc}]`)
            .value = this.cart.details[item.SKU].locations[loc]
        return
      }
      if (item.maxQty && _val > item.maxQty) {
        this.$vex.dialog.alert({
          message: `The maximum quantity for this item is ${item.maxQty}`
        })
        !this.collection.settings.multilocation
          ? this.$el.querySelector('input[data-sku=' + item.SKU + ']')
            .value = this.cart.items[item.SKU]
          : this.$el.querySelector(`input[data-sku=${item.SKU}][data-loc=${loc}]`)
            .value = this.cart.details[item.SKU].locations[loc]
        return
      }

      if (!this.collection.settings.multilocation) {
        this.cart.items[item.SKU] = _val
        // _profile[this.cartPath].items[item.SKU] = _val
        item.qty = _val
        if (item.units) {
          _.find(i => i.key === item.unitField.key, item.units).qty = _val
        }

        for (const itm in this.cart.items) {
          if (!this.cart.items[itm]) {
            delete this.cart.items[itm]
            delete this.cart.details[itm]
          }
        }
      } else {
        this.cart.details[item.SKU].locations[loc] = _val
        this.cart.items[item.SKU] = _.reduce(
          (acc, i) => {
            acc += i
            return acc
          },
          0,
          _.values(this.cart.details[item.SKU].locations)
        )
      }

      // change quantity of an item
      this.profileUpdate({ user: this.user, profile: _profile }).then(() => {
        this.setCart(this.cartPath)
      })
    },
    removeItem (item, loc) {
      const _profile = _.assign({}, this.user.profile)

      if (!this.collection.settings.multilocation) {
        delete this.cart.items[item.SKU]
        delete this.cart.details[item.SKU]
      } else {
        delete this.cart.details[item.SKU].locations[loc]
        this.cart.items[item.SKU] = _.reduce(
          (acc, i) => {
            acc += i
            return acc
          },
          0,
          _.values(this.cart.details[item.SKU].locations)
        )
      }

      this.profileUpdate({ user: this.user, profile: _profile }).then(() => {
        this.getCartProducts(this.$route.query.cartid)
        this.setCart(this.cartPath)
      })
    },
    removeOrphans () {
      const _profile = _.assign({}, this.user.profile)
      let _cart = _.assign({}, this.cart)
      this.orphanProducts.forEach(i => {
        // _.unset(i, _cart.items);
        // _.unset(i, _cart.details);
        delete _cart.items[i]
        delete _cart.details[i]
      })
      console.info(' cleared orphans each', _cart)
      _cart.items = _.reduce(
        (acc, v, k) => {
          if (!k || k === 'undefined') return acc
          acc[k] = v
          return acc
        },
        {},
        _cart.items
      )
      _cart.details = _.reduce(
        (acc, v, k) => {
          if (!k || (typeof k === 'string' && k === 'undefined')) return acc
          acc[k] = v
          return acc
        },
        {},
        _cart.details
      )
      console.info(' cleared orphans reduce', _cart)
      _profile.cart = _cart

      this.profileUpdate({ user: this.user, profile: _profile }).then(() => {
        this.orphanProducts = _.difference(
          _.keys(this.cart.items),
          _.map(i => i.SKU, this.cartProducts)
        )
      })
    },
    clearCart () {
      let _profile = _.assign({}, this.user.profile)
      this.cart.items = {}
      this.cart.details = {}
      _profile = _.set(this.$route.query.cartid, this.cart, _profile)
      this.profileUpdate({ user: this.user, profile: _profile })
      this.clearCartMemory(this.cartPath)
      // this.getCartProducts(this.$route.query.cartid)
    },
    currencyLocale (val) {
      return accounting.formatMoney(
        val,
        this.appconfig.locale[this.$i18n.locale].currency
      )
    },
    async setContext () {
      if (this.$route.query.cartid === 'cart') {
        this.cartPath = 'cart'
        this.setCart(this.cartPath)
        this.isCollection = _.values(this.cart.details)[0].type === 'collection'
        if (_.keys(this.user.profile.cart.items).length) {
          this.getCartProducts(this.$route.query.cartid)
            .then(res => {
              if (res.success) {
                if (res.diff) {
                  this.checkUser()
                  this.orphanProducts = res.diff
                // this.$vex.dialog.alert(res.message + (res.diff ? ' -- ' + res.diff.join(', ') : ''))
                }
              }
            })
        }
      } else if (this.$route.query.cartid === 'customorders') {
        this.cartPath = 'customorders'
        this.setCart(this.cartPath)
        this.isCollection = _.values(this.cart.details)[0].type === 'collection'
        if (_.keys(this.cart.items).length) {
          this.getCustomorderProducts()
        }
      } else {
        this.cartPath = this.$route.query.cartid
        this.setCart(this.cartPath)
        this.isCollection = _.values(this.cart.details)[0].type === 'collection'

        const _cart = _.get(this.cartPath, this.user.profile.shoppingcarts)
        if (_.keys(_cart).length) {
          this.getCartProducts(this.$route.query.cartid)
            .then(res => {
              if (res.success) {
                if (res.diff) {
                  this.checkUser()
                  this.orphanProducts = res.diff
                // this.$vex.dialog.alert(res.message + (res.diff ? ' -- ' + res.diff.join(', ') : ''))
                }
              }
            })
        }
      }
      if (this.isCollection) {
        await this.getCollectionProducts({ id: this.collection._id, key: 'url' })
      } else {
        await this.getProgramProducts({
          id: this.collection.url,
          key: 'url',
          list:
            this.collection.settings &&
            this.collection.settings.layout === 'standard'
        })
      }
      this.loaded = true
    }
  },

}
</script>

<style src="@/../../../templates/brand/components/styles/shop/Cart.css"></style>
